import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const CreateTestSuite = () => {
  return (
    <LayoutComponent>
      <h2>Create a Test Suite</h2>

      <h3>Steps to Create a Test Suite:</h3>
      <ol>
        <li>
          On the <strong>Test Suites</strong> main page, click{" "}
          <strong>+New Test Suite</strong> to begin the creation process.
        </li>
        <li>
          The <strong>Create Test Suite</strong> modal will appear. Fill in all
          the details for your test suite.
        </li>
        <li>
          Once the details are complete, click <strong>Save</strong> to create
          the test suite.
        </li>
        <li>
          After creating the test suite, you can associate it with new or
          existing test cases. Go to the <strong>Create Test Case</strong> page
          to create a test case and link it to the newly created test suite. You
          can also edit existing test cases and add the test suite to them.
        </li>
      </ol>

      <div className="testimonials">
        <h5>Note</h5>
        <p>
          A test suite is required in order to create test cases. Ensure that
          you create at least one test suite before proceeding with test case
          creation.
        </p>
      </div>
    </LayoutComponent>
  )
}

export default CreateTestSuite
